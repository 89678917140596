<template>
    <div>
      <div class="title">
        <div class="container box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          <div class="icon-text-wrapper">
            <img src="@/assets/icons/back.svg" v-if="localDisplayLang === 'eng'" alt="EngBack" @click="initialRoute" />
            <img src="@/assets/icons/back.svg" class="rotate-img" v-else @click="initialRoute" alt="AraBack" />
            &nbsp;&nbsp;&nbsp;
            <p v-if="screen && screen.title" style="margin: 0px 10px">{{ localDisplayLang === "ara" ? screen.title.ar : screen.title.en }}</p>
          </div>
        </div>
      </div>
      <div v-if="loader">
        <div v-for="(item, i) in 4" :key="i">
          <categoriesPlaceholder></categoriesPlaceholder>
        </div>
      </div>
      <div class="grid-view" v-else>
        <noorPlayGrid
          :contents="screenContent"
          :section="screen"
          :playerInstance="playerInstance"
          :message="errorMessage"
          :isScrollable="isScrollable"
          @loadMore="loadMoreContents()"
        ></noorPlayGrid>
      </div>
      <div v-if="moreContentloader" style="padding: 1rem;">
        <Loading class="loader"></Loading>
      </div>
    </div>
  </template>
  
  <script>
  import { eventBus } from "@/eventBus";
  import { mapGetters, mapActions } from "vuex";

  export default {
    props: {
      menuDetail: {
        type: Object,
      },
      section: {
        type: [Object, String],
      },
      playerInstance: {
        type: Object,
      },
      routeObject : {
        type: Object
      }
    },
    data() {
      return {
        loader: true,
        screenContent: [],
        errorMessage: null,
        localDisplayLang: null,
        page: 1,
        isScrollable: false,
        isMpegRequired: false,
        screen:{},
        totalcount: null,
        recommendedListData: null,
        moreContentloader: false
      };
    },
    computed: {
      ...mapGetters(["appConfig"]),
    },
    watch: {
      $route() {
        console.log("route change");
        this.screenContent = [];
        this.page = 1;
        this.getRecommendations();
      },
    },
    created() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.screenContent = [];
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
      // this.getRecommendations();
    },
    mounted() {
      let _this = this;
      this.screen = JSON.parse(localStorage.getItem("screenData"));
      this.getRecommendations();
      window.addEventListener("scroll", () => {
        var scrollHeight, totalHeight;
        scrollHeight = document.body.scrollHeight;
        totalHeight = window.scrollY + window.innerHeight;
        if (totalHeight >= scrollHeight) {
          console.error("screenContent", this.totalcount, this.screenContent.length)
          if (this.totalcount > this.screenContent.length) {
            this.page = this.page + 1;
            this.listRecommendationContent(this.recommendedListData);
          } else {
            this.moreContentloader = false;
          }
        } else {
          self.loader = false;
        }
      });
    },
    methods: {
      ...mapActions(["recommendedList", "listContent"]),
      getRecommendations() {
        let self = this;
        let payload = {
          country: this.country ? this.country.CountryCode : '',
        };
        this.recommendedList(payload).then((recommendedList) => {
          if(recommendedList.length > 0){
            this.recommendedListData = recommendedList;
            this.listRecommendationContent(recommendedList);
          }
        }).catch((err) => {
          console.log('recommendedError', err);
        });
      },
      initialRoute() {
        this.$router.push({ name: "Home" });
      },
      loadMoreContents() {
        console.log("load More");
        // this.page = this.page + 1;
        // this.getAllContents(this.isMpegRequired);
      },
      listRecommendationContent(list) {
        const seriesObjIds = [];
        list.map((element) => {
          seriesObjIds.push(`"${element}"`);
        })
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          // contentlist: list,
          displaylanguage: this.localDisplayLang,
          page: this.page
        };
        this.listContent(payload).then((data) => {
            console.error("JUST_DATA", data.data);
            let sliceList = data.data.data;
            this.totalcount = data.data.totalcount;
            this.screenContent = [...this.screenContent, ...sliceList];
            this.loader = false;
            if(this.totalcount > this.screenContent.length) {
              this.moreContentloader = true;
            }
            // this.loading = false;
          }).catch((err) => {
            console.log('top10Error', err);
          });
      }
    },
    components: {
      categoriesPlaceholder: () => import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
      noorPlayGrid: () => import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
      Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    },
    beforeDestroy() {
      this.screenContent = [];
      // eventBus.$off("check-for-series-route");
      // eventBus.$off("get-seeAll-data");
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  .title {
    background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
    .box-series {
      display: flex;
  
      .icon-text-wrapper {
        display: flex;
  
        &:hover {
          cursor: pointer;
          img {
            opacity: 1;
          }
  
          p {
            color: $clr-light-gd3;
          }
        }
  
        img {
          cursor: pointer;
          width: 1rem;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
        .rotate-img {
          transform: rotate(180deg);
        }
        p {
          opacity: 1;
          color: #848486;
          font-family: $font-regular;
          font-size: 0.8rem;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 1.5rem;
        }
      }
    }
  }
  .grid-view {
    margin: 0 auto;
    width: 100vw;
    height: 100%;
  }
  </style>
  